import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UserCardWithBackground,UserCompanyCardWithBackground } from '../../../../components';
import { api, helpers, UserContext, constants } from "../../../../utils";
import Button from '@mui/material/Button';


const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
      </Box>
      <Box>
        {items.map((item, i) => (
          <UserCardWithBackground/>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const defaultNewUser = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  mobilePhoneNumber: '',
  timezone: constants.DEFAULT_TIMEZONE,
  roles: []
};



const Content = () => {
    const theme = useTheme();
    const userCtx = useContext(UserContext);
    const [user, setUser] = useState(userCtx.currentUser);
    const [profileList, setProfileList] = useState([]);
    const [companyProfileList, setCompanyProfileList] = useState([]);
    const [primaryColor, setPrimaryColor] = useState("#111");
    const [secondaryColor, setSecondaryColor] = useState("#111");
    const [highlightColor, setHighlightColor] = useState("#111");
    const [bottomColor, setBottomColor] = useState("#111");
    const [companyPrimaryColor, setCompanyPrimaryColor] = useState("#111");
    const [companySecondaryColor, setCompanySecondaryColor] = useState("#111");
    const [companyHighlightColor, setCompanyHighlightColor] = useState("#111");
    const [companyBottomColor, setCompanyBottomColor] = useState("#111");
    const [userProfileDefault, setUserProfileDefaultDisplay] = useState(true);
    const [companyProfileDefault, setCompanyProfileDefaultDisplay] = useState(true);
    const [display, setDisplay] = useState(true);
    const [message, setMessage] = useState(null);
    const [companyProfileThemeFetched, setCompanyProfileThemeFetched] = useState(false);
    const navigate = useNavigate();

    const getProfileTheme = () =>
        api.fetch(`profile/UserTheme/${user.id}`).then((r) => {
            if (r.data) {
                setPrimaryColor(r.data.primaryColor);
                setSecondaryColor(r.data.secondaryColor);
                setHighlightColor(r.data.highlightColor);
                setBottomColor(r.data.bottomColor);
                setUserProfileDefault(r.data.isDefault);
            }
        });

    const getUserCompanyProfileTheme = () =>
        api.fetch(`profile/UserCompanyTheme/${user.id}`).then((r) => {
            if (r.data) {
                setCompanyPrimaryColor(r.data.primaryColor);
                setCompanySecondaryColor(r.data.secondaryColor);
                setCompanyHighlightColor(r.data.highlightColor);
                setCompanyBottomColor(r.data.bottomColor);
                setCompanyProfileDefault(r.data.isDefault);
                setCompanyProfileThemeFetched(true);
                console.log(r.data, 'r.data');
            } else {
                setCompanyProfileThemeFetched(false);
            }
        }).catch((error) => {
            console.error('Failed to fetch company profile theme:', error);
            setCompanyProfileThemeFetched(false);
        });

    useEffect(() => {
        setUser(userCtx.currentUser);
    }, [userCtx.currentUser]);

    const getAllUserProfiles = () =>
        api.post(`profile/ProfileList/${user.id}`).then((r) => {
            if (r.data) {
                setProfileList(r.data);
            }
        });

    const getAllUserCompanyProfiles = () =>
        api.post(`profile/ProfileList/${user.id}`).then((r) => {
            if (r.data) {
                setCompanyProfileList(r.data);
            }
        });

    useEffect(() => {
        getAllUserProfiles();
        getAllUserCompanyProfiles();
        getUserCompanyProfileTheme();
        getProfileTheme();
    }, []);

    const setUserProfileDefault = () => {
        api.post(`profile/SetUserProfileDefault/${user.id}`).then((r) => {
            if (r.data) {
                setUserProfileDefaultDisplay(true);
                setCompanyProfileDefaultDisplay(false);
                // Update profiles after setting default
                getAllUserProfiles();
                getAllUserCompanyProfiles();
            }
        }).catch((error) => {
            console.error('Failed to set user profile as default:', error);
            // Optionally provide user feedback here
        });
    };

    const setCompanyProfileDefault = () => {
        api.post(`profile/SetCompanyProfileDefault/${user.id}`).then(() => {
            setUserProfileDefaultDisplay(false);
            setCompanyProfileDefaultDisplay(true);
            // Update profiles after setting default
            getAllUserProfiles();
            getAllUserCompanyProfiles();
        }).catch((error) => {
            console.error('Failed to set company profile as default:', error);
        });
    };

    return (
        <Box>
            <Box marginBottom={6}>
                <Button
                    sx={{
                        height: 54,
                        backgroundColor: userProfileDefault ? 'lightgreen' : 'darkgrey',
                        '&:hover': {
                            backgroundColor: userProfileDefault ? 'lightgreen' : 'darkgrey'
                        }
                    }}
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={setUserProfileDefault}
                >
                    Set User Profile as Default
                </Button>
            </Box>
            <Box>
                {profileList.map((profile, i) => (
                    <UserCardWithBackground
                        key={i}
                        primaryColor={profile.primaryColor}
                        secondaryColor={profile.secondaryColor}
                        highlightColor={profile.highlightColor}
                        bottomColor={profile.bottomColor}
                        user={user}
                        userProfile={profile}
                    />
                ))}
            </Box>
            {companyProfileThemeFetched && (
                <>
                    <Box marginBottom={6}>
                        <Button
                            sx={{
                                height: 54,
                                backgroundColor: companyProfileDefault ? 'lightgreen' : 'darkgrey',
                                '&:hover': {
                                    backgroundColor: companyProfileDefault ? 'lightgreen' : 'darkgrey'
                                }
                            }}
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={setCompanyProfileDefault}
                        >
                            Set Company Profile as Default
                        </Button>
                    </Box>
                    <Box>
                        {companyProfileList.map((profile2, i) => (
                            <UserCompanyCardWithBackground
                                key={i}
                                companyPrimaryColor={companyPrimaryColor}
                                companySecondaryColor={companySecondaryColor}
                                companyHighlightColor={companyHighlightColor}
                                companyBottomColor={companyBottomColor}
                                user={user}
                                userProfile={profile2}
                            />
                        ))}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Content;