import React, { useState, useEffect, Fragment } from 'react';

const FilterableScreen = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="filterableScreen">
        {props.filters && <div className="filterPagerHeader">{props.filters}</div>}
        <div className="contentWithFilters">{props.children}</div>
        {props.pager && <div className="pagerFooter">{props.pager}</div>}
      </div>
      {/* {isMobile ? (
        <Fragment>
          <div className="mobileWithFilterFooter">{props.children}</div>
          <div className="mobileFilterFooter">{props.filters}</div>
        </Fragment>
      ) : (
        <div className="filterableScreen">
          {props.filters && <div className="filterPagerHeader">{props.filters}</div>}
          <div className="contentWithFilters">{props.children}</div>
          {props.pager && <div className="pagerFooter">{props.pager}</div>}
        </div>
      )} */}
    </div>
  );
};

export default FilterableScreen;