import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import valid from 'card-validator';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Page from '../components/Page';
import Main from '../../../layouts/Main';
import UserCompanyTheme from '../../../components/UserCompanyTheme';


const UserCompanyThemeEdit = () => {
    return (
    <Main>
      <Page>
        <Box style={{paddingLeft:"10%",paddingRight:"10%", justifyContent: 'center' }}>
          <UserCompanyTheme/>   
        </Box>
      </Page>
    </Main>
  );
};

export default UserCompanyThemeEdit;
