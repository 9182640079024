
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';  
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import React, { useState, useContext, useEffect, Fragment } from "react";
import Page from '../components/Page';
import Main from '../../../layouts/Main';
import CountryDropdown from '../../../components/CountryListDropdown';
import { api, helpers, UserContext, storage } from '../../../utils';
import _ from "lodash";
import NumberFormat from "react-number-format";

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
});

const General = () => {
  const userCtx = useContext(UserContext);
    const [user, setUser] = useState(userCtx.currentUser);
    const [phoneNumberError, setPhoneNumberError] = useState("");
  const [message, setMessage] = useState(null);
  const initialValues = {
    fullName: '',
    bio: '',
    email: '',
    country: '',
    city: '',
    address: '',
  };

  function onChange(fieldName, fieldValue) {
    let newUser = Object.assign({}, user);
    newUser[fieldName] = fieldValue;
    setUser(newUser);
  }
  
  function onSubmit() {
    let warnings = [];
    if (!user.firstName || _.trim(user.firstName).length < 2) {
      warnings.push("Please provide a valid first name.");
    }
    if (!user.lastName || _.trim(user.lastName).length < 2) {
      warnings.push("Please provide a valid last name.");
    }
    if (!user.email || !helpers.emailIsValid(user.email)) {
      warnings.push("Please provide a valid email.");
      }
    //  if (!user.mobilePhoneNumber || !helpers.formatPhoneNumber(user.mobilePhoneNumber)) {
    //  warnings.push("Please provide a valid phone number.");
    //  }
    //  if (!user.company || _.trim(user.company)) {
    //  warnings.push("Please provide a valid Company.");
    //  }
    //  if (!user.title || _.trim(user.title)) {
    //  warnings.push("Please provide a valid Title.");
    //}
    if (warnings.length) {
      setMessage({
        text: warnings.join(" "),
        flavor: "alert-danger",
      });
      return;
    } else {
      setMessage(null);
    }
    api
      .post("UserAdmin/SaveUser", user)
      .then((response) => {
          if (response.data.success) {
              api
                  .post("UserAdmin/SaveUserCard", user)
                  .then((response) => { })
          setMessage({
            text: "Your profile has been updated successfully!",
            flavor: "alert-success",
          });
          setTimeout(() => userCtx.signIn(user), 3000);
        } else {
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler);
  }

  const formik = useFormik({
      initialValues: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          company: user.company,
          title: user.title,
          mobilePhoneNumber: user.mobilePhoneNumber,
      },
      validationSchema: validationSchema,
    onSubmit,
  });

    const handlePhoneNumberChange = (value) => {
        const inputValue = value.replace(/\D/g, ""); // Remove non-digit characters
        const formattedValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3, 6)}-${inputValue.slice(6, 10)}`;
        setUser((prevUser) => ({
            ...prevUser,
            mobilePhoneNumber: formattedValue,
        }));
        if (inputValue.length === 10 || inputValue.length === 0) {
            setPhoneNumberError(""); // Clear error message if length is valid
        } else {
            setPhoneNumberError("Phone number must be 10 digits.");
        }
    };

    return (
        <Main>
            <Page>
                <Box>
                    <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight={700}
                        sx={{ color: 'white' }} // Set title color to white
                    >
                        Change your private information
                    </Typography>
                    <Typography variant={'subtitle2'} sx={{ color: 'white' }}> {/* Set text color to white */}
                        Please read our{' '}
                        <Link color={'white'} href={'/company-terms'} underline={'none'}>
                            terms of use
                        </Link>{' '}
                        to be informed how we manage your private data.
                    </Typography>
                    <Box paddingY={4}>
                        <Divider sx={{ backgroundColor: 'white' }} /> {/* Set divider color to white */}
                    </Box>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    variant={'subtitle2'}
                                    sx={{ marginBottom: 2, color: 'white' }} // Set label color to white
                                    fontWeight={700}
                                >
                                    First Name *
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    name={'firstName'}
                                    fullWidth
                                    value={user.firstName || ""}
                                    onChange={(event) =>
                                        onChange(event.target.name, event.target.value)
                                    }
                                    InputLabelProps={{
                                        style: { color: 'white' }, // Set placeholder text color to white
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white', // Set input text color to white
                                            '& fieldset': {
                                                borderColor: 'white', // Set input border color to white
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'white',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'white',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    variant={'subtitle2'}
                                    sx={{ marginBottom: 2, color: 'white' }} // Set label color to white
                                    fontWeight={700}
                                >
                                    Last Name *
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    name={'lastName'}
                                    fullWidth
                                    value={user.lastName || ""}
                                    onChange={(event) =>
                                        onChange(event.target.name, event.target.value)
                                    }
                                    InputLabelProps={{
                                        style: { color: 'white' }, // Set placeholder text color to white
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white', // Set input text color to white
                                            '& fieldset': {
                                                borderColor: 'white', // Set input border color to white
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'white',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'white',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant={'subtitle2'}
                                    sx={{ marginBottom: 2, color: 'white' }} // Set label color to white
                                    fontWeight={700}
                                >
                                    Email *
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    name={'email'}
                                    fullWidth
                                    value={user.email || ""}
                                    onChange={(event) =>
                                        onChange(event.target.name, event.target.value)
                                    }
                                    InputLabelProps={{
                                        style: { color: 'white' }, // Set placeholder text color to white
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white', // Set input text color to white
                                            '& fieldset': {
                                                borderColor: 'white', // Set input border color to white
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'white',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'white',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant={'subtitle2'}
                                    sx={{ marginBottom: 2, color: 'white' }} // Set label color to white
                                    fontWeight={700}
                                >
                                    Company
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    name={'company'}
                                    fullWidth
                                    value={user.company || ""}
                                    onChange={(event) =>
                                        onChange(event.target.name, event.target.value)
                                    }
                                    InputLabelProps={{
                                        style: { color: 'white' }, // Set placeholder text color to white
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white', // Set input text color to white
                                            '& fieldset': {
                                                borderColor: 'white', // Set input border color to white
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'white',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'white',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant={'subtitle2'}
                                    sx={{ marginBottom: 2, color: 'white' }} // Set label color to white
                                    fontWeight={700}
                                >
                                    Title
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    name={'title'}
                                    fullWidth
                                    value={user.title || ""}
                                    onChange={(event) =>
                                        onChange(event.target.name, event.target.value)
                                    }
                                    InputLabelProps={{
                                        style: { color: 'white' }, // Set placeholder text color to white
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white', // Set input text color to white
                                            '& fieldset': {
                                                borderColor: 'white', // Set input border color to white
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'white',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'white',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
              <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2" sx={{ marginBottom: 2, color: 'white' }} fontWeight={700}>
                                  Mobile Phone Number *
                              </Typography>
                              <NumberFormat
                                  customInput={TextField}
                                  format="(###) ###-####"
                                  mask="_"
                                  name="mobilePhoneNumber"
                                  fullWidth
                                  error={phoneNumberError.length > 0}
                                  helperText={phoneNumberError}
                                  value={user.mobilePhoneNumber}
                                  onValueChange={(values) => handlePhoneNumberChange(values.value)}
                                  InputLabelProps={{ style: { color: 'white' } }}
                                  sx={{
                                      '& .MuiOutlinedInput-root': {
                                          color: 'white', // Input text color
                                          '& fieldset': {
                                              borderColor: 'white', // Border color
                                          },
                                          '&:hover fieldset': {
                                              borderColor: 'white',
                                          },
                                          '&.Mui-focused fieldset': {
                                              borderColor: 'white',
                                          },
                                      },
                                      '& .MuiInputLabel-root': {
                                          color: 'white', // Label color
                                      },
                                  }}
                              />
              </Grid>
                <Grid item xs={12}>
               
                <Divider />
              </Grid>
              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'center' }}
                  justifyContent={'space-between'}
                  width={1}
                  margin={'0 auto'}
                >
                                    <Box marginBottom={{ xs: 1, sm: 0 }}>
                                        <Typography variant={'subtitle2'} sx={{ color: 'white' }}> {/* Set text color to white */}
                                            You may also consider to update your{' '}
                                            <Link
                                                color={'white'}
                                                href={'/account/theme'}
                                                underline={'none'}
                                            >
                                                billing information.
                                            </Link>
                                        </Typography>
                                    </Box>
                                    <Button
                                        onClick={() => onSubmit()} component={'a'}
                                        variant="contained"
                                        color="customColor"
                                        size="large"
                                    >
                    Save
                  </Button>
                </Box>
              </Grid>
             </Grid>
          </form>
        </Box>
      </Page>
    </Main>
  );
};

export default General;
