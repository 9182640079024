﻿/* eslint-disable indent */ /* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useState, useContext, useEffect, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { api, helpers, UserContext, constants } from "../utils";
import _, { isNil } from "lodash";
import DebounceInput from "react-debounce-input";
import EditIcon from "@mui/icons-material/Edit";
import NumberFormat from "react-number-format";
import {
  Snackbar,
  Alert,
  Box,
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { text } from "@fortawesome/fontawesome-svg-core";
import ColorPickerComponent from "./ColorPickerComponent";

const Square = styled(Paper)(({ theme }) => ({
  width: "20em",
  height: "15em",
  position: "relative",
  overflow: "hidden",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(3),
  display: "inline-block", // Make squares display inline
}));
const Rectangle = styled(Paper)(({ theme }) => ({
  width: "20em",
  height: "4em",
  position: "relative",
  overflow: "hidden",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  display: "inline-block", // Make squares display inline
}));

const TABS = {
  DETAILS: "Profile Details",
  PASSWORD: "Credentials",
};
const Color1 = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
}));

const Color2 = styled("div")(() => ({
  position: "relative",
  width: "95%",
  height: "2rem",
}));

const UserCompanyTheme = () => {
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const { id } = useParams();
  const [userApps, setUserApps] = useState([]);
  const [profileNumber, setProfileNumber] = useState(1);
  const [userId, setUserId] = useState(0);
  const [profileList, setProfileList] = useState([]);
  const [userProfileId, setUserProfileId] = useState(5);
  const [primaryColor, setPrimaryColor] = useState("#fff");
  const [secondaryColor, setSecondaryColor] = useState("#444");
  const [highlightColor, setHighlightColor] = useState("#111");
  const [bottomColor, setBottomColor] = useState("#111");
  const [namePanelColor, setNamePanelColor] = useState("#111");
  const [companyPanelColor, setCompanyPanelColor] = useState("#111");
  const [requireContactCode, setRequireContactCode] = useState(false);
  const [requireProfileCode, setRequireProfileCode] = useState(false);
  const [profileCode, setProfileCode] = useState("");
  const [profile, setProfile] = useState();
  const [contactCode, setContactCode] = useState("");
  const [message, setMessage] = useState(null);
  const [useWhiteText, setUseWhiteText] = useState(true);
  const navigate = useNavigate();
  const [showAllBusinessApps, setShowAllBusinessApps] = useState(false);
  const [showAllPaymentApps, setShowAllPaymentApps] = useState(false);
  const [showAllSocialApps, setShowAllSocialApps] = useState(false);
  const [showAllCustomApps, setShowAllCustomApps] = useState(false);
  const [businessApps, setBusinessApps] = useState([]);
  const [paymentApps, setPaymentApps] = useState([]);
  const [socialApps, setSocialApps] = useState([]);
  const [customApps, setCustomApps] = useState([]);
  const [allBusinessApps, setAllBusinessApps] = useState([]);
  const [allPaymentApps, setAllPaymentApps] = useState([]);
  const [allSocialApps, setAllSocialApps] = useState([]);
  const [allCustomApps, setAllCustomApps] = useState([]);
  const [commonBusinessAppIds, setCommonBusinessAppIds] = useState([]);
  const [commonPaymentAppIds, setCommonPaymentAppIds] = useState([]);
  const [commonSocialAppIds, setCommonSocialAppIds] = useState([]);
  const [commonCustomAppIds, setCommonCustomAppIds] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [companyId, setCompanyId] = useState(0);
  const [companyProfileId, setCompanyProfileId] = useState();
  const [userCompanyProfileId, setUserCompanyProfileId] = useState();
  const [profileName, setProfileName] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
    const [profilePhone, setProfilePhone] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");

  const findCommonBusinessApps = (businessApps, allBusinessApps) => {
    const userBusinessAppIds = businessApps.map((item) => item.companyApp.id);
    const commonBusinessApps = allBusinessApps.filter((item) =>
      userBusinessAppIds.includes(item.id)
    );
    return commonBusinessApps;
  };

  const findCommonPaymentApps = (paymentApps, allPaymentApps) => {
    const userPaymentAppIds = paymentApps.map((item) => item.companyApp.id);
    const commonPaymentApps = allPaymentApps.filter((item) =>
      userPaymentAppIds.includes(item.id)
    );
    return commonPaymentApps;
  };

  const findCommonSocialApps = (socialApps, allSocialApps) => {
    const userSocialAppIds = socialApps.map((item) => item.companyApp.id);
    const commonSocialApps = allSocialApps.filter((item) =>
      userSocialAppIds.includes(item.id)
    );
    return commonSocialApps;
  };

  const findCommonCustomApps = (customApps, allCustomApps) => {
    const userCustomAppIds = customApps.map((item) => item.app.id);
    const commonCustomApps = allCustomApps.filter((item) =>
      userCustomAppIds.includes(item.id)
    );
    return commonCustomApps;
    };

    const formatPhoneNumber = (phoneNumber) => {
        const formattedPhoneNumber = phoneNumber.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "($1) $2-$3"
        );
        return formattedPhoneNumber;
    };
    const handlePhoneNumberChange = (value) => {
        const inputValue = value.replace(/\D/g, ""); // Remove non-digit characters
        const formattedValue = formatPhoneNumber(inputValue);
        setProfilePhone(formattedValue); // Always update the formatted value
        if (inputValue.length === 10 || inputValue.length === 0) {
            setPhoneNumberError(""); // Clear error message if length is valid
        } else {
            setPhoneNumberError("Phone number must be 10 digits.");
        }
    };


  const isPremiumUser =
    userCtx.currentUser.subscriptionId > 0 &&
    new Date(userCtx.currentUser.subscriptionEndDate) >= new Date();

  useEffect(() => {
    assignUserId();
    assignCompanyId();
  }, []);

  useEffect(() => {
    if (userId) {
      getUserCompanyProfile(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (userCompanyProfileId && companyId) {
      getCompanyApps();
      getAllUserCompanyProfiles();
      getUserCompanyApps(userCompanyProfileId);
    }
  }, [userCompanyProfileId, companyId]);

  const getUserCompanyProfile = async (userId) => {
    try {
      const response = await api.post(
        `Company/UserCompanyProfileList/${userId}`
      );
      console.log(response, "API response");

      if (response.data) {
        const userCompanyProfileId = response.data.companyProfileId; // Correct field
        setUserCompanyProfileId(userCompanyProfileId);
        console.log(response.data, "response.data in getUserCompanyProfile");
        setUserProfileId(response.data.companyProfileId); // Correct field
        setProfileName(response.data.profileName);
        setTitle(response.data.title);
        setCompany(response.data.company);
        setProfileEmail(response.data.profileEmail); // Set email from response
        setProfilePhone(response.data.profilePhone); // Set phone from response
      } else {
        console.log("No data returned from API");
      }
    } catch (error) {
      console.error("Failed to fetch company profile", error);
    }
  };

  const getCompanyApps = async () => {
    try {
      const response = await api.post(
        `company/CurrentCompanyProfileAppList/${1}`
      );
      if (response.data) {
        const normalizeApp = (item) => ({
          companyApp: {
            companyProfileId: item.companyProfileId,
            app: {
              appGroupId: item.app.appGroupId,
              iconUrl: item.app.iconUrl,
              linkPrefix: item.app.linkPrefix,
              id: item.app.id,
              name: item.app.name,
            },
            customAppName: item.customAppName || "",
            deactivatedAt: item.deactivatedAt,
            deactivatedByUserId: item.deactivatedByUserId,
            id: item.id,
            linkSufix: item.linkSufix || "",
            createdAt: item.createdAt,
            createdByuserId: item.createdByuserId,
            userCompanyProfileId: item.userCompanyProfileId,
          },
        });

        const allBusinessApps = response.data
          .filter((item) => item.app.appGroupId === 1)
          .map(normalizeApp);
        const allPaymentApps = response.data
          .filter((item) => item.app.appGroupId === 2)
          .map(normalizeApp);
        const allSocialApps = response.data
          .filter((item) => item.app.appGroupId === 3)
          .map(normalizeApp);
        const allCustomApps = response.data
          .filter((item) => item.app.appGroupId === 4)
          .map(normalizeApp);

        setAllBusinessApps(allBusinessApps);
        setAllPaymentApps(allPaymentApps);
        setAllSocialApps(allSocialApps);
        setAllCustomApps(allCustomApps);
      }
    } catch (error) {
      console.error("Failed to fetch company apps", error);
    }
  };

  const getUserCompanyApps = async (userCompanyProfileId) => {
    try {
      const response = await api.post(
        `company/CurrentUserCompanyProfileAppList/${userCompanyProfileId}`
      );
      if (response.data) {
        const businessApps = response.data.filter(
          (item) => item.companyApp.app.appGroupId === 1
        );

        const paymentApps = response.data.filter(
          (item) => item.companyApp.app.appGroupId === 2
        );
        const socialApps = response.data.filter(
          (item) => item.companyApp.app.appGroupId === 3
        );
        const customApps = response.data.filter(
          (item) => item.companyApp.app.appGroupId === 4
        );
        setBusinessApps(businessApps);
        setPaymentApps(paymentApps);
        setSocialApps(socialApps);
        setCustomApps(customApps);
        getUserCompanyAppsPayload();
      }
    } catch (error) {
      console.error("Failed to fetch company apps", error);
    }
  };

  const getAllUserCompanyProfiles = async () => {
    try {
      const response = await api.post(`company/List/${userId}/${1}`);
      if (response.data) {
        setProfileList(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch user company profiles", error);
    }
  };

  function assignUserId() {
    setUserId(user.id);
  }

  function assignCompanyId() {
    if (user.companyRoles && user.companyRoles.length > 0) {
      setCompanyId(user.companyRoles[0].companyId);
    }
  }

  function saveProfileThemeAndApps() {
    console.log(userCompanyProfileId, "userCompanyProfileId at save");
    console.log(profileName, "Profile Name at save"); // Log the profile name state

    const allApps = getUserCompanyAppsPayload();
    console.log(allApps, "saveProfileThemeAndApps - all Apps");

    let warnings = [];
    allApps.forEach((app) => {
      console.log("Checking app:", app);
      if (!app.linkSufix || _.trim(app.linkSufix).length === 0) {
        warnings.push(
          `All active apps must have a link. Missing link in app with ID: ${app.id}`
        );
      }
    });

    if (warnings.length) {
      console.log("Warnings:", warnings);
      showMessage(warnings.join(" "), "alert-danger");
      return;
    }

    if (!userCompanyProfileId) {
      console.log("userCompanyProfileId is not set.");
      showMessage(
        "User Company Profile ID is not set. Please wait.",
        "alert-danger"
      );
      return;
    }

    let themePayload = {
      profileName: _.trim(profileName),
      title: _.trim(title),
      company: _.trim(company),
      userId: _.trim(user.id),
      userCompanyProfileId: userCompanyProfileId,
      primaryColor: _.trim(primaryColor),
      activationCode: "",
      secondaryColor: _.trim(secondaryColor),
      highlightColor: _.trim(highlightColor),
      bottomColor: _.trim(bottomColor),
      requireProfileCode,
      requireContactCode,
      useWhiteText,
      lockedProfileCode: _.trim(profileCode),
      lockedContactCode: _.trim(contactCode),
      namePanelColor: _.trim(namePanelColor),
      companyPanelColor: _.trim(companyPanelColor),
      companyProfileId: companyProfileId,
      profileNumber: profileNumber,
      profileEmail: _.trim(profileEmail),
      profilePhone: _.trim(profilePhone),
      apps: allApps,
    };

    console.log(themePayload, "Theme Payload");

    api
      .post(`company/SaveUserCompanyProfileTheme/${user.id}/0`, themePayload)
      .then((response) => {
        if (response.data.success) {
          showMessage("Profile Theme Update Was Successful", "alert-success");
        } else {
          showMessage(
            "There was an error saving your profile theme, please refresh the page and try again.",
            "alert-danger"
          );
        }
      })
      .catch((error) => {
        console.error("Error saving profile theme", error);
        showMessage(
          "There was an error saving your profile theme, please refresh the page and try again.",
          "alert-danger"
        );
      });
  }

  const getUserCompanyAppsPayload = () => {
    const allApps = [
      ...businessApps.map((app) => ({
        CompanyApp: app.companyApp,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        linkSufix: app.linkSufix || "",
        name: app.name || app.companyApp.app.name,
        userCompanyProfileId: app.userCompanyProfileId || userCompanyProfileId,
        userId: app.userId,
        companyAppId: app.companyApp.id,
      })),
      ...paymentApps.map((app) => ({
        CompanyApp: app.companyApp,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        linkSufix: app.linkSufix || "",
        name: app.name || app.companyApp.app.name,
        userCompanyProfileId: app.userCompanyProfileId || userCompanyProfileId,
        userId: app.userId,
        companyAppId: app.companyApp.id,
      })),
      ...socialApps.map((app) => ({
        CompanyApp: app.companyApp,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        linkSufix: app.linkSufix || "",
        name: app.name || app.companyApp.app.name,
        userCompanyProfileId: app.userCompanyProfileId || userCompanyProfileId,
        userId: app.userId,
        companyAppId: app.companyApp.id,
      })),
      ...customApps.map((app) => ({
        CompanyApp: app.companyApp,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        customAppName: app.customAppName || "",
        linkSufix: app.linkSufix || "",
        name: app.name || app.companyApp.app.name,
        userCompanyProfileId: app.userCompanyProfileId || userCompanyProfileId,
        userId: app.userId,
        companyAppId: app.companyApp.id,
      })),
    ];
    return allApps;
  };

  const showMessage = (text, type) => {
    setMessage({ text, flavor: type });
    setOpenSnackbar(true);
  };

  const getAllCompanyApps = async () => {
    try {
      const response = await api.post(
        `company/CurrentCompanyProfileAppList/${1}`
      );
      if (response.data) {
        const allBusinessApps = response.data.filter(
          (item) => item.app.appGroupId === 1
        );
        const allPaymentApps = response.data.filter(
          (item) => item.app.appGroupId === 2
        );
        const allSocialApps = response.data.filter(
          (item) => item.app.appGroupId === 3
        );
        const allCustomApps = response.data.filter(
          (item) => item.app.appGroupId === 4
        );
        setAllBusinessApps(allBusinessApps);
        setAllPaymentApps(allPaymentApps);
        setAllSocialApps(allSocialApps);
        setAllCustomApps(allCustomApps);

        const commonBusinessApps = findCommonBusinessApps(
          businessApps,
          allBusinessApps
        );
        setCommonBusinessAppIds(commonBusinessApps.map((app) => app.id));

        const commonPaymentApps = findCommonPaymentApps(
          paymentApps,
          allPaymentApps
        );
        setCommonPaymentAppIds(commonPaymentApps.map((app) => app.id));

        const commonSocialApps = findCommonSocialApps(
          socialApps,
          allSocialApps
        );
        setCommonSocialAppIds(commonSocialApps.map((app) => app.id));

        const commonCustomApps = findCommonCustomApps(
          customApps,
          allCustomApps
        );
        setCommonCustomAppIds(commonCustomApps.map((app) => app.id));
      }
    } catch (error) {
      console.error("Failed to fetch user apps", error);
    }
  };

  useEffect(() => {
    getAllCompanyApps();
  }, [businessApps, paymentApps, socialApps, customApps]);

  //selectedApp.companyApp.app.iconUrl
  const handleToggleBusinessApp = (selectedApp) => {
    if (!selectedApp || !selectedApp.app || !selectedApp.id) {
      console.error(
        "Selected app or its properties are undefined:",
        selectedApp
      );
      return;
    }

    const isBusinessAppInUserApps = businessApps.some(
      (app) =>
        app.companyApp &&
        app.companyApp.app &&
        app.companyApp.app.id === selectedApp.app.id
    );

    if (isBusinessAppInUserApps) {
      setBusinessApps((prevBusinessApps) =>
        prevBusinessApps.filter(
          (app) => app.companyApp && app.companyApp.id !== selectedApp.id
        )
      );
    } else {
      const formattedApp = {
        companyApp: {
          companyProfileId: selectedApp.companyProfileId,
          app: {
            appGroupId: selectedApp.app.appGroupId,
            iconUrl: selectedApp.app.iconUrl,
            linkPrefix: selectedApp.app.linkPrefix,
            id: selectedApp.app.id,
            name: selectedApp.app.name,
          },
          customAppName: selectedApp.customAppName || "",
          deactivatedAt: selectedApp.deactivatedAt,
          deactivatedByUserId: selectedApp.deactivatedByUserId,
          id: selectedApp.id,
          linkSufix: selectedApp.linkSufix || "", // Ensure linkSufix is set
          createdAt: selectedApp.createdAt,
          createdByuserId: selectedApp.createdByuserId,
          userCompanyProfileId: selectedApp.userCompanyProfileId,
        },
        linkSufix: selectedApp.linkSufix || "",
        companyAppId: selectedApp.id,
        createdByuserId: selectedApp.createdByuserId,
        customAppName: selectedApp.customAppName || "",
        deactivatedAt: selectedApp.deactivatedAt,
        deactivatedByUserId: selectedApp.deactivatedByUserId,
        id: selectedApp.id,
        name: selectedApp.app.name || "",
        userCompanyProfileId: selectedApp.userCompanyProfileId,
        userId: user.id,
      };

      setBusinessApps((prevBusinessApps) => [
        ...prevBusinessApps,
        formattedApp,
      ]);
    }

    setCommonBusinessAppIds((prevCommonApps) =>
      isBusinessAppInUserApps
        ? prevCommonApps.filter((id) => id !== selectedApp.id)
        : [...prevCommonApps, selectedApp.id]
    );
  };

  const handleTogglePaymentApp = (selectedApp) => {
    if (!selectedApp || !selectedApp.app || !selectedApp.id) {
      console.error(
        "Selected app or its properties are undefined:",
        selectedApp
      );
      return;
    }

    const isPaymentAppInUserApps = paymentApps.some(
      (app) =>
        app.companyApp &&
        app.companyApp.app &&
        app.companyApp.app.id === selectedApp.app.id
    );

    if (isPaymentAppInUserApps) {
      setPaymentApps((prevPaymentApps) =>
        prevPaymentApps.filter(
          (app) => app.companyApp && app.companyApp.id !== selectedApp.id
        )
      );
    } else {
      const formattedApp = {
        companyApp: {
          companyProfileId: selectedApp.companyProfileId,
          app: {
            appGroupId: selectedApp.app.appGroupId,
            iconUrl: selectedApp.app.iconUrl,
            linkPrefix: selectedApp.app.linkPrefix,
            id: selectedApp.app.id,
            name: selectedApp.app.name,
          },
          customAppName: selectedApp.customAppName || "",
          deactivatedAt: selectedApp.deactivatedAt,
          deactivatedByUserId: selectedApp.deactivatedByUserId,
          id: selectedApp.id,
          linkSufix: selectedApp.linkSufix || "",
          createdAt: selectedApp.createdAt,
          createdByuserId: selectedApp.createdByuserId,
          userCompanyProfileId: selectedApp.userCompanyProfileId,
        },
        linkSufix: selectedApp.linkSufix || "",
        companyAppId: selectedApp.id,
        createdByuserId: selectedApp.createdByuserId,
        customAppName: selectedApp.customAppName || "",
        deactivatedAt: selectedApp.deactivatedAt,
        deactivatedByUserId: selectedApp.deactivatedByUserId,
        id: selectedApp.id,
        name: selectedApp.app.name || "",
        userCompanyProfileId: selectedApp.userCompanyProfileId,
        userId: user.id,
      };

      setPaymentApps((prevPaymentApps) => [...prevPaymentApps, formattedApp]);
    }

    setCommonPaymentAppIds((prevCommonApps) =>
      isPaymentAppInUserApps
        ? prevCommonApps.filter((id) => id !== selectedApp.id)
        : [...prevCommonApps, selectedApp.id]
    );
  };

  const handleToggleSocialApp = (selectedApp) => {
    if (!selectedApp || !selectedApp.app || !selectedApp.id) {
      console.error(
        "Selected app or its properties are undefined:",
        selectedApp
      );
      return;
    }

    const isSocialAppInUserApps = socialApps.some(
      (app) =>
        app.companyApp &&
        app.companyApp.app &&
        app.companyApp.app.id === selectedApp.app.id
    );

    if (isSocialAppInUserApps) {
      setSocialApps((prevSocialApps) =>
        prevSocialApps.filter(
          (app) => app.companyApp && app.companyApp.id !== selectedApp.id
        )
      );
    } else {
      const formattedApp = {
        companyApp: {
          companyProfileId: selectedApp.companyProfileId,
          app: {
            appGroupId: selectedApp.app.appGroupId,
            iconUrl: selectedApp.app.iconUrl,
            linkPrefix: selectedApp.app.linkPrefix,
            id: selectedApp.app.id,
            name: selectedApp.app.name,
          },
          customAppName: selectedApp.customAppName || "",
          deactivatedAt: selectedApp.deactivatedAt,
          deactivatedByUserId: selectedApp.deactivatedByUserId,
          id: selectedApp.id,
          linkSufix: selectedApp.linkSufix || "",
          createdAt: selectedApp.createdAt,
          createdByuserId: selectedApp.createdByuserId,
          userCompanyProfileId: selectedApp.userCompanyProfileId,
        },
        linkSufix: selectedApp.linkSufix || "",
        companyAppId: selectedApp.id,
        createdByuserId: selectedApp.createdByuserId,
        customAppName: selectedApp.customAppName || "",
        deactivatedAt: selectedApp.deactivatedAt,
        deactivatedByUserId: selectedApp.deactivatedByUserId,
        id: selectedApp.id,
        name: selectedApp.app.name || "",
        userCompanyProfileId: selectedApp.userCompanyProfileId,
        userId: user.id,
      };

      setSocialApps((prevSocialApps) => [...prevSocialApps, formattedApp]);
    }

    setCommonSocialAppIds((prevCommonApps) =>
      isSocialAppInUserApps
        ? prevCommonApps.filter((id) => id !== selectedApp.id)
        : [...prevCommonApps, selectedApp.id]
    );
  };

  return (
    <div>
      <div
        className="LOOK AT ME"
        style={{
          padding: 3,
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between", // Changed from center to space-between
          alignItems: "center",
          color: 'white' // Ensure all text within this div is white
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
                          sx={{
                              '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                      borderColor: 'white',
                                  },
                                  '&:hover fieldset': {
                                      borderColor: 'gray',
                                  },
                                  '&.Mui-focused fieldset': {
                                      borderColor: 'lightBlue',
                                  },
                                  color: 'white', // Input text color
                              },
                              '& .MuiInputLabel-root': {
                                  color: 'white', // Label color
                              },
                              '& .MuiFormHelperText-root': {
                                  color: 'white', // Helper text color
                              },
                          }}
                          label="Profile Name *"
                          variant="outlined"
                          name="profileName"
                          value={profileName}
                          fullWidth
                          onChange={(e) => setProfileName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
                          sx={{
                              '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                      borderColor: 'white',
                                  },
                                  '&:hover fieldset': {
                                      borderColor: 'gray',
                                  },
                                  '&.Mui-focused fieldset': {
                                      borderColor: 'lightBlue',
                                  },
                                  color: 'white', // Input text color
                              },
                              '& .MuiInputLabel-root': {
                                  color: 'white', // Label color
                              },
                              '& .MuiFormHelperText-root': {
                                  color: 'white', // Helper text color
                              },
                          }}
                          label="Profile Title *"
                          variant="outlined"
                          name="Title"
                          value={title}
                          fullWidth
                          onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>

            <TextField
                          sx={{
                              '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                      borderColor: 'white',
                                  },
                                  '&:hover fieldset': {
                                      borderColor: 'gray',
                                  },
                                  '&.Mui-focused fieldset': {
                                      borderColor: 'lightBlue',
                                  },
                                  color: 'white', // Input text color
                              },
                              '& .MuiInputLabel-root': {
                                  color: 'white', // Label color
                              },
                              '& .MuiFormHelperText-root': {
                                  color: 'white', // Helper text color
                              },
                          }}
                          label="Profile Email *"
                          variant="outlined"
                          name="profileEmail"
                          fullWidth
                          value={profileEmail}
                          onChange={(e) => setProfileEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>

                      <NumberFormat
                          sx={{
                              '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                      borderColor: 'white',
                                  },
                                  '&:hover fieldset': {
                                      borderColor: 'gray',
                                  },
                                  '&.Mui-focused fieldset': {
                                      borderColor: 'lightBlue',
                                  },
                                  color: 'white', // Input text color
                              },
                              '& .MuiInputLabel-root': {
                                  color: 'white', // Label color
                              },
                              '& .MuiFormHelperText-root': {
                                  color: 'white', // Helper text color
                              },
                          }}
                          customInput={TextField}
                          label="Profile Phone *"
                          format="(###) ###-####"
                          variant="outlined"
                          mask="_"
                          name="profilePhone"
                          fullWidth
                          error={phoneNumberError.length > 0}
                          helperText={phoneNumberError}
                          value={profilePhone}
                          onValueChange={(values) => handlePhoneNumberChange(values.value)}
                      />
          </Grid>
              </Grid>
              <div style={{ flexGrow: 1, paddingBottom:13 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingY: 2,
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ fontWeight: 700, paddingBottom: 1, color: 'white' }}
            >
              Business Apps
            </Typography>
                      <Button onClick={() => setShowAllBusinessApps(!showAllBusinessApps)} sx={{ color: 'white', border: '1px solid white', }}>
              <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
            </Button>
          </Box>
          <>
            {businessApps.length < 1 ? (
              <div sx={{ paddingBottom: "15px", color: 'white' }}>
                No Business Apps Currently Active
              </div>
            ) : null}
            {showAllBusinessApps ? (
              <Grid className="FirstOne" container spacing={1}>
                {allBusinessApps.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    xs={3}
                    sm={3}
                    md={3}
                    sx={{ textAlign: "center", marginBottom: "1em" }}
                  >
                    <>
                      <Box
                        component="img"
                        sx={{
                          height: "3rem",
                          width: "3rem",
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                          cursor: "pointer",
                          border: commonBusinessAppIds.includes(item.id)
                            ? "2px solid blue"
                            : "none",
                        }}
                        alt="Business App"
                        src={item.app.iconUrl}
                        onClick={() => handleToggleBusinessApp(item)}
                      />
                    </>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {businessApps.map((selectedApp, index) => (
              <div className="DIALOUGGGEEE" sx={{ width: 1, color: 'white' }} key={index}>
                <>
                  <img
                    src={selectedApp.companyApp.app.iconUrl}
                    alt="App Icon"
                    style={{ height: "3em", marginRight: "0.5rem" }}
                  />
                  <DebounceInput
                    element={TextField}
                    debounceTimeout={500}
                    label={selectedApp.companyApp.app.linkPrefix}
                    sx={{
                      width: "75%",
                      paddingBottom: "1em",
                      '& .MuiOutlinedInput-root': { color: 'white' },
                      '& .MuiInputLabel-root': { color: 'white' },
                    }}
                    value={selectedApp.linkSufix}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setBusinessApps((prevBusinessApps) => {
                        const updatedBusinessApps = prevBusinessApps.map(
                          (app) =>
                            app.companyApp.id === selectedApp.companyApp.id
                              ? {
                                  ...app,
                                  linkSufix: newValue,
                                  companyApp: {
                                    ...app.companyApp,
                                    linkSufix: newValue,
                                  },
                                }
                              : app
                        );
                        return updatedBusinessApps;
                      });
                    }}
                  />
                </>
              </div>
            ))}
          </>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingY: 2,
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ fontWeight: 700, paddingBottom: 1, color: 'white' }}
            >
              Payment Apps
            </Typography>
                      <Button onClick={() => setShowAllPaymentApps(!showAllPaymentApps)} sx={{ color: 'white', border: '1px solid white', }}>
              <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
            </Button>
          </Box>
          {paymentApps.length < 1 ? (
            <div sx={{ paddingBottom: "15px", color: 'white' }}>
              No Payment Apps Currently Active
            </div>
          ) : null}
          {showAllPaymentApps ? (
            <Grid className="FirstOne" container spacing={1}>
              {allPaymentApps.map((item, index) => (
                <Grid
                  item
                  key={index}
                  xs={3}
                  sm={3}
                  md={3}
                  sx={{ textAlign: "center", marginBottom: "1em" }}
                >
                  <>
                    <Box
                      component="img"
                      sx={{
                        height: "3rem",
                        width: "3rem",
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                        cursor: "pointer",
                        border: commonPaymentAppIds.includes(item.id)
                          ? "2px solid blue"
                          : "none",
                      }}
                      alt="Payment App"
                      src={item.app.iconUrl}
                      onClick={() => handleTogglePaymentApp(item)}
                    />
                  </>
                </Grid>
              ))}
            </Grid>
          ) : null}
          {paymentApps.map((selectedApp, index) => (
            <div className="DIALOUGGGEEE" sx={{ width: 1, color: 'white' }} key={index}>
              <>
                <img
                  src={selectedApp.companyApp.app.iconUrl}
                  alt="App Icon"
                  style={{ height: "3em", marginRight: "0.5rem" }}
                />
                <DebounceInput
                  element={TextField}
                  debounceTimeout={500}
                  label={selectedApp.companyApp.app.linkPrefix}
                  sx={{
                    width: "75%",
                    paddingBottom: "1em",
                    '& .MuiOutlinedInput-root': { color: 'white' },
                    '& .MuiInputLabel-root': { color: 'white' },
                  }}
                  value={selectedApp.linkSufix}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setPaymentApps((prevPaymentApps) => {
                      const updatedPaymentApps = prevPaymentApps.map(
                        (app) =>
                          app.companyApp.id === selectedApp.companyApp.id
                            ? {
                                ...app,
                                linkSufix: newValue,
                                companyApp: {
                                  ...app.companyApp,
                                  linkSufix: newValue,
                                },
                              }
                            : app
                      );
                      return updatedPaymentApps;
                    });
                  }}
                />
              </>
            </div>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingY: 2,
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ fontWeight: 700, paddingBottom: 1, color: 'white' }}
            >
              Social Apps
            </Typography>
                      <Button onClick={() => setShowAllSocialApps(!showAllSocialApps)} sx={{ color: 'white', border: '1px solid white', }}>
              <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
            </Button>
          </Box>
          {socialApps.length < 1 ? (
            <div sx={{ paddingBottom: "15px", color: 'white' }}>
              No Social Apps Currently Active
            </div>
          ) : null}
          {showAllSocialApps ? (
            <Grid className="FirstOne" container spacing={1}>
              {allSocialApps.map((item, index) => (
                <Grid
                  item
                  key={index}
                  xs={3}
                  sm={3}
                  md={3}
                  sx={{ textAlign: "center", marginBottom: "1em" }}
                >
                  <>
                    <Box
                      component="img"
                      sx={{
                        height: "3rem",
                        width: "3rem",
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                        cursor: "pointer",
                        border: commonSocialAppIds.includes(item.id)
                          ? "2px solid blue"
                          : "none",
                      }}
                      alt="Social App"
                      src={item.app.iconUrl}
                      onClick={() => handleToggleSocialApp(item)}
                    />
                  </>
                </Grid>
              ))}
            </Grid>
          ) : null}
          {socialApps.map((selectedApp, index) => (
            <div className="DIALOUGGGEEE" sx={{ width: 1, color: 'white' }} key={index}>
              <>
                <img
                  src={selectedApp.companyApp.app.iconUrl}
                  alt="App Icon"
                  style={{ height: "3em", marginRight: "0.5rem" }}
                />
                <DebounceInput
                  element={TextField}
                  debounceTimeout={500}
                  label={selectedApp.companyApp.app.linkPrefix}
                  sx={{
                    width: "75%",
                    paddingBottom: "1em",
                    '& .MuiOutlinedInput-root': { color: 'white' },
                    '& .MuiInputLabel-root': { color: 'white' },
                  }}
                  value={selectedApp.linkSufix}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setSocialApps((prevSocialApps) => {
                      const updatedSocialApps = prevSocialApps.map((app) =>
                        app.companyApp.id === selectedApp.companyApp.id
                          ? {
                              ...app,
                              linkSufix: newValue,
                              companyApp: {
                                ...app.companyApp,
                                linkSufix: newValue,
                              },
                            }
                          : app
                      );
                      return updatedSocialApps;
                    });
                  }}
                />
              </>
            </div>
          ))}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            sx={{ color: 'white' }}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity={message?.flavor === "alert-danger" ? "error" : "success"}
              sx={{ width: "100%", color: 'white' }}
            >
              {message?.text}
            </Alert>
          </Snackbar>
        </div>
      </div>
      <div
        className="row-end"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
                        component={'a'}
                        variant="contained"
                        color="customColor"
                        size="large"
                        onClick={saveProfileThemeAndApps}
                    >
                        SAVE
                    </Button>
      </div>
    </div>
  );;}
const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputBox: {
    flexGrow: 10,
    margin: 5,
    width: 100,
  },
};
export default UserCompanyTheme;
