/* eslint-disable indent */ /* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useState, useContext, useEffect, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Select, MenuItem } from "@mui/material";
import Switch from "@mui/material/Switch";
import { default as QrEdit } from "./QrEdit";
import { api, helpers, UserContext, constants } from "../utils";
import _, { isNil } from "lodash";
import DebounceInput from "react-debounce-input";
import EditIcon from "@mui/icons-material/Edit";
import UserApps from "./UserApps";
import {
  Snackbar,
  Alert,
  Box,
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { text } from "@fortawesome/fontawesome-svg-core";
import ColorPickerComponent from "./ColorPickerComponent";

const Square = styled(Paper)(({ theme }) => ({
  width: "20em",
  height: "15em",
  position: "relative",
  overflow: "hidden",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(3),
  display: "inline-block", // Make squares display inline
}));
const Rectangle = styled(Paper)(({ theme }) => ({
  width: "20em",
  height: "4em",
  position: "relative",
  overflow: "hidden",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  display: "inline-block", // Make squares display inline
}));

const TABS = {
  DETAILS: "Profile Details",
  PASSWORD: "Credentials",
};
const Color1 = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
}));

const Color2 = styled("div")(() => ({
  position: "relative",
  width: "95%",
  height: "2rem",
}));

const calculateLast7Days = () => {
  const today = new Date();
  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 7);

  return {
    startDate: last7Days.toISOString().slice(0, 10),
    endDate: today.toISOString().slice(0, 10),
  };
};

const UserThemeArticles = ({}) => {
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const { startDate, endDate } = calculateLast7Days();
  const [currentStartDate, setCurrentStartDate] = useState(startDate);
  const [currentEndDate, setCurrentEndDate] = useState(endDate);
  const { id } = useParams();
  const [userApps, setUserApps] = useState([]);
  const [profileNumber, setProfileNumber] = useState(1);
  const [userId, setUserId] = useState(0);
  const [profileList, setProfileList] = useState([]);
  const [userProfileId, setUserProfileId] = useState(5); // initial value can remain 5
  const [primaryColor, setPrimaryColor] = useState("#fff");
  const [secondaryColor, setSecondaryColor] = useState("#444");
  const [highlightColor, setHighlightColor] = useState("#111");
  const [bottomColor, setBottomColor] = useState("#111");
  const [namePanelColor, setNamePanelColor] = useState("#111");
  const [companyPanelColor, setCompanyPanelColor] = useState("#111");
  const [requireContactCode, setRequireContactCode] = useState(false);
  const [requireProfileCode, setRequireProfileCode] = useState(false);
  const [profileCode, setProfileCode] = useState("");
  const [profile, setProfile] = useState();
  const [contactCode, setContactCode] = useState("");
  const [message, setMessage] = useState(null);
  const [useWhiteText, setUseWhiteText] = useState(true);
  const navigate = useNavigate();
  const [showAllBusinessApps, setShowAllBusinessApps] = useState(false);
  const [showAllMoneyApps, setShowAllMoneyApps] = useState(false);
  const [showAllSocialApps, setShowAllSocialApps] = useState(false);
  const [showAllCustomApps, setShowAllCustomApps] = useState(false);
  const [businessApps, setBusinessApps] = useState([]);
  const [paymentApps, setPaymentApps] = useState([]);
  const [socialApps, setSocialApps] = useState([]);
  const [customApps, setCustomApps] = useState([]);
  const [allBusinessApps, setAllBusinessApps] = useState([]);
  const [allPaymentApps, setAllPaymentApps] = useState([]);
  const [allSocialApps, setAllSocialApps] = useState([]);
  const [allCustomApps, setAllCustomApps] = useState([]);
  const [commonBusinessAppIds, setCommonBusinessAppIds] = useState([]);
  const [commonPaymentAppIds, setCommonPaymentAppIds] = useState([]);
  const [commonSocialAppIds, setCommonSocialAppIds] = useState([]);
  const [commonCustomAppIds, setCommonCustomAppIds] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [userClickList, setUserClickList] = useState([]);
  const [profileVisits, setProfileVisits] = useState({});

  const isPremiumUser =
    userCtx.currentUser.subscriptionId > 0 &&
    new Date(userCtx.currentUser.subscriptionEndDate) >= new Date();

  console.log(userCtx.currentUser.subscriptionId, "subId");
  console.log(userCtx.currentUser.subscriptionEndDate, "endDate");

  const fetchProductLink = async () => {
    try {
      console.log("UserID before sending:", userId);
      const response = await api.post(
        "userAdmin/generate-link",
        { UserId: user.Id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Product Link:", response);
      window.location.href = response.data;
    } catch (error) {
      console.error("Error generating product link:", error);
    }
  };

  const showMessage = (text, type) => {
    setMessage({ text, flavor: type });
    setOpenSnackbar(true);
  };

  function assignUserId() {
    setUserId(user.id);
  }

  function saveProfileThemeAndApps() {
    saveUserArticles();
  }

  const getAllUserProfiles = () =>
    api.post(`profile/ProfileList/${user.id}`).then((r) => {
      if (r.data) {
        setProfileList(r.data);
      }
    });

  const getProfileDetails = async () => {
    try {
      const response = await api.fetch(
        `profile/GetProfileColors/${user.id}/${profileNumber}`
      );
      if (response.data) {
        setPrimaryColor(response.data.primaryColor);
        setSecondaryColor(response.data.secondaryColor);
        setHighlightColor(response.data.highlightColor);
        setBottomColor(response.data.bottomColor);
        setCompanyPanelColor(response.data.companyPanelColor);
        setNamePanelColor(response.data.namePanelColor);
        setUserProfileId(response.data.id);
        setProfile(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch profile details", error);
    }
  };

  const handleProfileChange = (event) => {
    setProfileNumber(event.target.value);
  };

  const getUserProfileVisits = async () => {
    try {
      const payload = { startDate, endDate };
      const response = await api.post(
        `profile/GetUserProfileVisits/${userProfileId}`,
        payload
      );
      if (response.data) {
        setProfileVisits(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch user profile visits", error);
    }
  };

  useEffect(() => {
    if (userProfileId) {
      getUserArticles();
    }
  }, [userProfileId]);

  useEffect(() => {
    getProfileDetails();
    getAllUserProfiles();
  }, [profileNumber]);

  const [userProfileArticles, setUserProfileArticles] = useState([
    {
      id: 1,
      article: {
        title: "Article 1",
        thumbnailUrl: "Thumbnail 2",
        linkUrl: "URL 1",
        description: "Description 2",
      },
      display: true,
    },
    {
      id: 2,
      article: {
        title: "Article 1",
        thumbnailUrl: "Thumbnail 2",
        linkUrl: "URL 1",
        description: "Description 2",
      },
      display: true,
    },
    {
      id: 3,
      article: {
        title: "Article 1",
        thumbnailUrl: "Thumbnail 2",
        linkUrl: "URL 1",
        description: "Description 2",
      },
      display: true,
    },
  ]);

  const handleCheckboxChange = (articleId) => {
    setUserProfileArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === articleId
          ? { ...article, display: !article.display }
          : article
      )
    );
  };

  const getUserArticles = async () => {
    try {
      const response = await api.post(
        `profile/CurrentProfileArticleList/${userProfileId}`
      );
      if (response.data) {
        setUserProfileArticles(response.data);
        console.log(response.data, "response");
      }
    } catch (error) {
      console.error("Failed to fetch user articles", error);
    }
  };

  function saveUserArticles() {
    console.log(userProfileArticles);
    let payload = userProfileArticles.map((article) => ({
      id: article.id,
      article: {
        id: article.article.id,
        title: _.trim(article.article.title),
        linkUrl: _.trim(article.article.linkUrl),
        thumbnailUrl: _.trim(article.article.thumbnailUrl),
        description: _.trim(article.article.description),
      },
      userId: user.id,
      display: article.display,
    }));
    api
      .post(`Profile/SaveUserArticle/${user.id}`, payload)
      .then((response) => {
        if (!response.data.success) {
          setMessage({
            text: response.data.message,
            flavor: "alert-danger",
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  return (
    <div>
      <div
        className="LOOK AT ME"
        style={{
          padding: 3,
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <DialogContent className="p-0" sx={{ width: "100%" }}>
            <form>
              <Box>
                <Box
                  sx={{ justifyContent: "center", display: "flex" }}
                  paddingTop={2}
                  marginBottom={2}
                >
                  <Typography color={"white"} fontWeight={700} variant={"h5"}>
                    Articles
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  {userProfileArticles.map((article) => (
                    <Box sx={{ width: "85%" }} key={article.id}>
                      <Box
                        component={Accordion}
                        padding={0.5}
                        borderRadius={0.5}
                        sx={{
                          backgroundColor: "white",
                          "&::before": { display: "none" },
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id={`panel1a-header}`}
                          sx={{
                            backgroundColor: "#F7F7F7",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col xs={10}>
                            <Typography
                              sx={{ textAlign: "start" }}
                              fontWeight={600}
                            >
                              {article.article.title}
                            </Typography>
                          </Col>
                          <Col xs={2}>
                            <MenuIcon />
                          </Col>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item className="articleContainer" xs={12}>
                              <Typography
                                variant={"subtitle1"}
                                sx={{ marginBottom: 2 }}
                                fontWeight={700}
                              >
                                Edit Your Article Details
                              </Typography>
                              <TextField sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "text.primary", // Blue border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "text.primary", // Blue border color when hovered
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "text.primary", // Blue border color when focused
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "text.primary", // Blue color for the label (placeholder)
                                  },
                                  "& .MuiInputLabel-root.Mui-focused": {
                                    color: "text.primary", // Blue color when the label is focused
                                  },
                                  "& .MuiOutlinedInput-input": {
                                    "&::placeholder": {
                                      color: "text.primary", // Blue placeholder text color
                                      opacity: 1, // Ensures the placeholder is fully visible
                                    },
                                  },
                                }}
                                style={{ marginBottom: 30 }}
                                label="Title *"
                                variant="outlined"
                                name="title"
                                fullWidth
                                value={article.article.title}
                                onChange={(e) => {
                                  const updatedArticles =
                                    userProfileArticles.map((a) => {
                                      if (a.id === article.id) {
                                        return {
                                          ...a,
                                          article: {
                                            ...a.article,
                                            title: e.target.value,
                                          },
                                        };
                                      }
                                      return a;
                                    });
                                  setUserProfileArticles(updatedArticles);
                                }}
                                c
                              />

                              <TextField sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "text.primary", // Blue border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "text.primary", // Blue border color when hovered
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "text.primary", // Blue border color when focused
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "text.primary", // Blue color for the label (placeholder)
                                  },
                                  "& .MuiInputLabel-root.Mui-focused": {
                                    color: "text.primary", // Blue color when the label is focused
                                  },
                                  "& .MuiOutlinedInput-input": {
                                    "&::placeholder": {
                                      color: "text.primary", // Blue placeholder text color
                                      opacity: 1, // Ensures the placeholder is fully visible
                                    },
                                  },
                                }}
                                style={{ marginBottom: 30 }}
                                label="Content Url *"
                                variant="outlined"
                                name={"contentUrl"}
                                fullWidth
                                value={article.article.linkUrl}
                                onChange={(e) => {
                                  const updatedArticles =
                                    userProfileArticles.map((a) => {
                                      if (a.id === article.id) {
                                        return {
                                          ...a,
                                          article: {
                                            ...a.article,
                                            linkUrl: e.target.value,
                                          },
                                        };
                                      }
                                      return a;
                                    });
                                  setUserProfileArticles(updatedArticles);
                                }}
                              />

                              <TextField
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "text.primary", // Blue border color
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "text.primary", // Blue border color when hovered
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "text.primary", // Blue border color when focused
                                  },
                                },
                                "& .MuiInputLabel-root": {
                                  color: "text.primary", // Blue color for the label (placeholder)
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "text.primary", // Blue color when the label is focused
                                },
                                "& .MuiOutlinedInput-input": {
                                  "&::placeholder": {
                                    color: "text.primary", // Blue placeholder text color
                                    opacity: 1, // Ensures the placeholder is fully visible
                                  },
                                },
                              }}
                                style={{ marginBottom: 30 }}
                                label="Thumbnail Url *"
                                variant="outlined"
                                name={"thumbnailUrl"}
                                fullWidth
                                value={article.article.thumbnailUrl}
                                onChange={(e) => {
                                  const updatedArticles =
                                    userProfileArticles.map((a) => {
                                      if (a.id === article.id) {
                                        return {
                                          ...a,
                                          article: {
                                            ...a.article,
                                            thumbnailUrl: e.target.value,
                                          },
                                        };
                                      }
                                      return a;
                                    });
                                  setUserProfileArticles(updatedArticles);
                                }}
                              />
                              <TextField
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "text.primary", // Blue border color
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "text.primary", // Blue border color when hovered
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "text.primary", // Blue border color when focused
                                  },
                                },
                                "& .MuiInputLabel-root": {
                                  color: "text.primary", // Blue color for the label (placeholder)
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "text.primary", // Blue color when the label is focused
                                },
                                "& .MuiOutlinedInput-input": {
                                  "&::placeholder": {
                                    color: "text.primary", // Blue placeholder text color
                                    opacity: 1, // Ensures the placeholder is fully visible
                                  },
                                },
                              }}
                                style={{ marginBottom: 30 }}
                                label="Description"
                                variant="outlined"
                                name={"description"}
                                multiline
                                rows={3}
                                fullWidth
                                value={article.article.description}
                                onChange={(e) => {
                                  const updatedArticles =
                                    userProfileArticles.map((a) => {
                                      if (a.id === article.id) {
                                        return {
                                          ...a,
                                          article: {
                                            ...a.article,
                                            description: e.target.value,
                                          },
                                        };
                                      }
                                      return a;
                                    });
                                  setUserProfileArticles(updatedArticles);
                                }}
                              />
                              <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="flex-end"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    paddingTop={1}
                                    fontWeight={700}
                                    style={{ marginBottom: 0 }}
                                  >
                                    Show Post?
                                  </Typography>
                                  <input
                                    type="checkbox"
                                    checked={article.display}
                                    onChange={() =>
                                      handleCheckboxChange(article.id)
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Box>
                      <Box marginBottom={2}></Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </form>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                severity={
                  message?.flavor === "alert-danger" ? "error" : "success"
                }
                sx={{ width: "100%" }}
              >
                {message?.text}
              </Alert>
            </Snackbar>
          </DialogContent>
        </div>
      </div>
      <div
        className="row-end"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          size={"large"}
          variant={"contained"}
          type={"submit"}
          onClick={saveProfileThemeAndApps}
        >
          SAVE Theme and Apps
        </Button>
      </div>
    </div>
  );
};

const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputBox: {
    flexGrow: 10,
    margin: 5,
    width: 100,
  },
};

export default UserThemeArticles;
