import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import valid from 'card-validator';
import Box from '@mui/material/Box';


import Page from '../components/Page';
import Main from '../../../layouts/Main';
import UserThemeStats from '../../../components/UserThemeStats';


const UserThemeStatsEdit = () => {
    return (
    <Main>
      <Page>
        <Box style={{paddingLeft:"10%",paddingRight:"10%", justifyContent: 'center' }}>
          <UserThemeStats/>   
        </Box>
      </Page>
    </Main>
  );
};

export default UserThemeStatsEdit;
