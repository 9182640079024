
import { UserContext } from '../../../../utils';
import React, { useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import NavItem from './components/NavItem'; // Adjust the import according to your project structure
import { NexxLogoBlack} from '../../../../assets/images'; // Adjust the import according to your project structure

const Topbar = ({ onSidebarOpen, colorInvert = false }) => {
  const userCtx = useContext(UserContext);
  const handleSignout = userCtx.signOut;

  const pages = {
    company: [
      {
        title: "Customer Stories",
        href: "/customers",
      },
      {
        title: "About: Startup",
        href: "/about-side-cover",
      },
      
      {
        title: "Terms",
        href: "/company-terms",
      },
      {
        title: "Contact: Minimal",
        href: "/contact-page-cover",
      },
    ],
    
    secondary: [
      {
        title: "Pricing",
        href: "/pricing",
      },
      {
        title: "FAQ",
        href: "/faq",
      },
      {
        title: "Purchase",
        href: "https://6fe5d4-2c.myshopify.com/",
      },
    ],
    blog: [
      {
        title: "Profile Theme",
        href: "/account/theme",
      },
      {
        title: "Company Profile Theme",
        href: "/account/company/theme",
      },
      {
        title: "General Settings",
        href: "/account/general",
      },
      {
        title: "Wallet",
        href: "/wallet",
      },
      {
        title: "Sign Out",
        onClick: (e) => {
          e.preventDefault();
          handleSignout();
        },
      },
    ],
    portfolio: [
      {
        title: "Basic",
        href: "/portfolio-page",
      },
    ],
  };

  const theme = useTheme();

  const {
    company: companyPages,
    secondary: secondaryPages,
    blog: blogPages,
  } = pages;

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        component="a"
        href="/"
        title=" "
        width={{ xs: 100, md: 120 }}
      >
        <Box component={"img"} src={NexxLogoBlack} height={1} width={1} />
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
        <Button
          component="a"
          href="/"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: colorInvert ? theme.palette.common.white : theme.palette.text.primary,
            backgroundColor: 'transparent',
            fontWeight: "/" === "/" ? 600 : 400, // Replace "/" with your active link logic
            padding: theme.spacing(1),
            textTransform: "none",
            fontSize: "20px",
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
            }
          }}
        >
          <Typography
            fontWeight={"/" === "/" ? 400 : 400} // Replace "/" with your active link logic
            color={colorInvert ? theme.palette.common.white : theme.palette.text.primary}
          >
            Home
          </Typography>
        </Button>
        <Box marginLeft={4}>
          <NavItem
            title={"Company & Clients"}
            id={"company-pages"}
            items={companyPages}
            colorInvert={colorInvert}
          />
        </Box>
       
        <Box marginLeft={4}>
          <NavItem
            title={"Shop"}
            id={"secondary-pages"}
            items={secondaryPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"My Account"}
            id={"blog-pages"}
            items={blogPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
      
        </Box>
      </Box>
      <Box sx={{ paddingRight: 2, display: { xs: "block", md: "none" } }} alignItems={"center"}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            fill: "#fff",
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  colorInvert: PropTypes.bool,
};

export default Topbar;